$col-padding: rem(12px);

#login-modal,
#signup-modal {
    overflow: auto;

    .form-control-label {
        text-transform: capitalize;
    }

    .modal-header,
    .modal-body {
        margin-bottom: rem(16px) !important;
    }

    .form-group:not(.remember-me) {
        margin-bottom: rem(16px);
    }

    .modal-footer {
        display: block;

        .row {
            margin-left: -$col-padding;
            margin-right: -$col-padding;
        }

        [class*=col-] {
            padding-left: $col-padding;
            padding-right: $col-padding;

            @include media-breakpoint-down(md) {
                + [class*=col-] {
                    margin-top: rem(16px);
                }
            }

            button {
                width: 100%;
                text-transform: none;
            }
        }
    }
}

#login-modal {
    .forgot-password {
        &::first-letter {
            text-transform: uppercase;
        }
    }
}
